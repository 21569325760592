import React from 'react';

import Toggle from '../mui/Toggle';

import Strings from '../../constants/strings';

import { connect } from 'react-redux';
import * as actions from '../../actions';
import TextField from '../mui/TextField';
import LoadingDialog from '../LoadingDialog';

import validate from 'validate.js';
import { List, ListItem, ListItemText } from '@material-ui/core';
import CheckboxOperandValue from '../CheckboxEnableValue';

import UserLanguage from '../configurations/UserLanguage.js'

let language = UserLanguage.getLanguage();

const courtTypes = [
    'CRIMINAL',
    'CIVEL',
    'COMERCIAL',
    'AMBIENTAL',
    'DIGITAL',
    'PENAL',
    'CONSUMIDOR',
    'CONTRATUAL',
    'TRABALHISTA',
    'TRIBUTARIA',
    'INQUERITO POLICIAL',
    'ESPECIAL CIVEL',
    'ESPECIAL CRIMINAL',
    'FAZENDA'
];

const cardContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: null
};

const toggleStyle = {
    
};

const columnStyle = {
    width: 385,
};

const column2Style = {
    width: 530,
};

const subItemStyle = {
    width: '370px',
    background: '#ffffff'
};

const selectedSubItemStyle = {
    width: '370px',
    background: '#dddddd',
    color: '#0069ff'
};

class BackgroundCheck extends React.Component {
    constructor(props) {
        super(props);

        const menuItens = [
            { text: Strings.backgroundCheckConfigurations.MENU_BACKGROUND_CKECK_CONFIG[language] },
            { text: Strings.backgroundCheckConfigurations.MENU_BASIC_DATA[language] },
            { text: Strings.backgroundCheckConfigurations.MENU_KNOW_YOUR_CLIENT },
            { text: Strings.backgroundCheckConfigurations.MENU_PUBLIC_AGENT[language] },
            { text: Strings.backgroundCheckConfigurations.MENU_DEBIT_PRESENCE[language] },
            { text: Strings.backgroundCheckConfigurations.MENU_INCOME[language] },
            { text: Strings.backgroundCheckConfigurations.MENU_LAWSUITS_PRESENCE[language] },
            { text: Strings.backgroundCheckConfigurations.MENU_CLASSIST[language] },
            // { text: Strings.backgroundCheckConfigurations.MENU_CELEBRITY[language] },
            { text: Strings.backgroundCheckConfigurations.MENU_SOCIAL_BENEFIT[language] },
            { text: Strings.backgroundCheckConfigurations.MENU_RELATIONSHIP[language] },
            { text: Strings.backgroundCheckConfigurations.MENU_RELATIONSHIP_PJ[language] }
        ];

        this.state = {
            configurations: props.configurations,
            DEFAULT_KEY: 'menuListItem_',
            menuItens: menuItens,
            selected: menuItens[0]
        }
    }

    constraints = {
        Name: {
            presence: {
                message: 'Nome é obrigatório'
            },

            length: {
                maximum: 20,
                message: 'Nome deve ter menos de 20 caracteres.'
            }
        }
    };

    options = {
        fullMessages: false,
    };

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
        });
    }

    // -- HANDLERS -- //
    handleToggle = (event, toggled) => {
        const newState = this.state;

        const target = event.target;
        const value = toggled;
        const name = target.name;

        newState.configurations.BackGroundCheckConfigs[name] = value;

        if (name === "ReturnBoostRawData" && toggled) {
            newState.configurations.BackGroundCheckConfigs["ReturnBoostRawDataIfApproved"] = false;
            newState.configurations.BackGroundCheckConfigs["ReturnBoostRawDataIfNotApproved"] = false;
        }

        if (name === "ReturnBoostRawDataIfApproved") {
            newState.configurations.BackGroundCheckConfigs["ReturnBoostRawData"] = false;
            newState.configurations.BackGroundCheckConfigs["ReturnBoostRawDataIfNotApproved"] = false;
        }

        if (name === "ReturnBoostRawDataIfNotApproved") {
            newState.configurations.BackGroundCheckConfigs["ReturnBoostRawData"] = false;
            newState.configurations.BackGroundCheckConfigs["ReturnBoostRawDataIfApproved"] = false;
        }

        if (validate(newState.configurations, this.constraints) === undefined) {
            this.setState(newState);
            this.props.onSave(newState.configurations);
        }
    };

    handleDontLetSaveGroupWithoutToken = (configurations) => {
        if (this.state.selected.text == "Configurações gerais") {
            if (this.state.configurations.BackGroundCheckConfigs !== null &&
                this.state.configurations.BackGroundCheckConfigs !== undefined &&
                this.state.configurations.BackGroundCheckConfigs !== "") {
                {
                    if (configurations.BackGroundCheckConfigs.ReturnBoostRawData ||
                        configurations.BackGroundCheckConfigs.ReturnBoostRawDataIfApproved ||
                        configurations.BackGroundCheckConfigs.ReturnBoostRawDataIfNotApproved) {
                        if (configurations.BigBoostConfigs.Token === "" || configurations.BigBoostConfigs.Token === undefined || configurations.BigBoostConfigs.Token === null) {
                            localStorage.setItem("visitedBGCPage", true);
                            return false;
                        }
                        else {
                            localStorage.setItem("visitedBGCPage", false);
                            return;
                        }
                    }
                    // in case of all toggles was off
                    else {
                        localStorage.setItem("visitedBGCPage", false);
                        return
                    }
                }
            }
        }
    }

    handleReprovationPointChange = (event) => {
        const state = this.state;
        state.configurations.BackGroundCheckConfigs.LimitScore = event.target.value;
        this.setState({ state });
        this.props.onSave(state.configurations);
    };




    // -- RENDER -- //

    changeSelected = (value) => {
        var visitedBGCPage = localStorage.getItem("visitedBGCPage")
        if (visitedBGCPage == "true") {
            alert("Só é possivel Retornar dos dados utilizados no Background Check com o Token do BigBoost preenchido.\n\nInsira um Token ou Desabilite as opções de retorno de dados.");
            return;
        }
        const state = this.state;
        state.selected = value;
        this.setState(state.selected);
    }

    handledataToggle = (index, event) => {
        const funcName = `handledataToggle`;
        const newState = this.state;

        newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index].Enabled = event.target.checked;
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleScoreToggle = (index, item, event) => {
        const funcName = `handleScoreToggle`;
        const newState = this.state;

        newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index][item][0].UseIndividualScore = event.target.checked;
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }


    handleScoreRelatedToggle = (index, item, event, value) => {
        const funcName = `handleScoreToggle`;
        const newState = this.state;
        newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index][item][0].UseIndividualScore = value;
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handledataRelatedPeopleContentToggle = (index, event, value) => {
        const newState = this.state;
        newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index].Enabled = value;
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    toggleDebit = (index, event, value) => {
        const newState = this.state;
        newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PF_COLLECTIONS_QTY.Enabled = value;
        newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PF_COLLECTIONS_ORIGINS.Enabled = value;
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }


    onGenerate = (event, newValue) => {

        const newState = this.state

        if (newValue.includes(',')) {
            let listWords = newValue.split(',');
            for (let i = 0; i < listWords.length; i++) {
                newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[event[0]].Types[0][event[1]].push(listWords[i])
            }
        } else {
            newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[event[0]].Types[0][event[1]].push(newValue)
        }

        this.setState({ newState });
        this.props.onSave(newState.configurations);

    }

    onGenerateRelatedPeople = (event, newValue) => {

        const newState = this.state

        if (newValue.includes(',')) {
            let listWords = newValue.split(',');
            for (let i = 0; i < listWords.length; i++) {
                newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[event[0]].Types[0][event[1]].push(listWords[i])
            }
        } else {
            newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[event[0]].Types[0][event[1]].push(newValue)
        }

        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleValueMatchChange = (index, event, newValue, value) => {
        value = event.target.value;
        newValue = event.target.value;
        const funcName = `handleValueMatchChange`;
        const newState = this.state;
        console.info(funcName, `(before)`, `newState: `, newState, `value: `, value, `index: `, index, `newValue: `, newValue);
        if (index === 'BigBoostConfigsToken') {
            newState.configurations.BigBoostConfigs.Token = newValue || value;
            if (newState.configurations.BigBoostConfigs.Token !== null && newState.configurations.BigBoostConfigs.Token !== "") {
                localStorage.setItem("visitedBGCPage", false);
            }
        } else {
            if (index[1] === "Score" || index[1] === "Value") {
                if(index[0] != "PF_INCOME_ESTIMATE") {
                    newValue = parseInt(newValue);
                    if(isNaN(parseInt(newValue)))
                        newValue = 0;

                }
            }
            if (index.length === 2) {
                if (index[0] == "PROCESS_COURT_TYPES") {
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]].Types[0][index[1]] = value || newValue;
                } else {
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]][index[1]] = newValue || value;
                }
            }
            else if (index.length === 3 && index[0] === "KYC_AGE" && (index[1] === "Score" || index[1] === "Value" || index[1] === "Operand")) {
                var datasetConfig = newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]][index[1]];
                console.info(funcName, `datasetConfig: `, datasetConfig);

                if (datasetConfig === null || datasetConfig === undefined || typeof datasetConfig !== "object" || !Array.isArray(datasetConfig)) {
                }
                newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]]["Configs"][index[2]][index[1]] = newValue;
            }
            else if (index[0] == "PROCESS_COURT_TYPES") {
                if (index[3] === "Scores")
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]][index[1]][0][index[3]][index[2].toUpperCase()] = Number(newValue);
                else if (index[3] === "Value") {
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]][index[1]][0][index[3]][index[2]] = value;
                } else if (index[2] === "Party") {
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]][index[1]][0][index[2]] = index[3][value];
                }
            }
            else {
                if (index[1] === "Operand") {
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]].Configs[index[2]][index[1]] = newValue || value;
                }
                if (((index[1] === "Value")) || (index[1] === "Score")) {
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]].Configs[index[2]][index[1]] = newValue || value;
                }
            }
        }
        console.info(funcName, `(after)`, `newState: `, newState, `value: `, value, `index: `, index, `newValue: `, newValue);
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleValueRelatedPeopleMatchChange = (index, event, value, newValue) => {
        const newState = this.state;

        newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]][index[1]][index[2]] = newValue || value.props.children;

        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleValueRelatedPeopleContentMatchChange = (index, event, value, newValue) => {
        value = event.target.value;
        newValue = event.target.value
        const funcName = `handleValueRelatedPeopleContentMatchChange`;
        const newState = this.state;
        console.info(funcName, `(before)`, `newState: `, newState, `value: `, value, `index: `, index, `newValue: `, newValue);
        if (index === 'BigBoostConfigsToken') {
            newState.configurations.BigBoostConfigs.Token = newValue || value;
            if (newState.configurations.BigBoostConfigs.Token !== null && newState.configurations.BigBoostConfigs.Token !== "") {
                localStorage.setItem("visitedBGCPage", false);
            }
        } else {
            if (index[1] === "Score" || index[1] === "Value") {
                newValue = parseInt(newValue);
                if(isNaN(parseInt(newValue)))
                    newValue = 0;
            }
            if (index.length === 2) {
                if (index[0] == "PROCESS_COURT_TYPES") {
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index[0]].Types[0][index[1]] = value || newValue;
                } else {
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index[0]][index[1]] = newValue || value;
                }
            }
            else if (index.length === 3 && index[0] === "KYC_AGE" && (index[1] === "Score" || index[1] === "Value" || index[1] === "Operand")) {
                var datasetConfig = newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index[0]][index[1]];
                console.info(funcName, `datasetConfig: `, datasetConfig);

                newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index[0]]["Configs"][index[2]][index[1]] = newValue;

            }
            else if (index[0] == "PROCESS_COURT_TYPES") {
                if (index[3] === "Scores")
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index[0]][index[1]][0][index[3]][index[2].toUpperCase()] = Number(newValue);
                else if (index[3] === "Value") {
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index[0]][index[1]][0][index[3]][index[2]] = value;
                } else if (index[2] === "Party") {
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index[0]][index[1]][0][index[2]] = index[3][value];
                }
            }
            else {
                if (index[1] === "Operand") {
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index[0]].Configs[index[2]][index[1]] = newValue || value;
                }
                if ((index[1] === "Value") || (index[1] === "Score")) {
                    newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index[0]].Configs[index[2]][index[1]] = newValue || value;
                }
            }
        }
        console.info(funcName, `(after)`, `newState: `, newState, `value: `, value, `index: `, index, `newValue: `, newValue);
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleValueMatchMake = (index, event, value, newValue) => {
        const newState = this.state;

        if (index[2] === null) { //add item
            newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]][index[1]][0].Value.push(
                "");
        } else { //remove item
            newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]][index[1]][0].Value.splice(index[2], 1);
        }

        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleValueRelatedPeopleContentMatchMake = (index, event, value, newValue) => {
        console.log("related content")
        const newState = this.state;

        if (index[2] === null) { //add item
            newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index[0]][index[1]][0].Value.push(
                "");
        } else { //remove item
            newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index[0]][index[1]][0].Value.splice(index[2], 1);
        }

        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleValueRelatedPeopleMatchMake = (index, event, value, newValue) => {
        const newState = this.state;

        if (index[2] === null) { //add item
            newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]][index[1]].push("");
        } else { //remove item
            newState.configurations.BackGroundCheckConfigs.DataSetsConfigs[index[0]][index[1]].splice(index[2], 1);
        }

        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    renderContent(configurations, errors) {
        console.info(`renderContent :: DatasetsConfigs :: `, configurations.BackGroundCheckConfigs.DataSetsConfigs, `DataSetsConfigs.KYC_COURT_ORDERS: `, configurations.BackGroundCheckConfigs.DataSetsConfigs.KYC_COURT_ORDERS.Configs)

        const multiFilter = (obj) => [
            {
                type: 'select',
                field: 'Operand',
                label: Strings.backgroundCheckConfigurations.OPERAND[language],
                styleWidth: '90px',
                values: ['>=', '=', '<=', '<', '>'],
                default: '>=',
                value: obj.Operand
            },

            {
                type: 'number',
                field: 'Value',
                value: obj.Value,
            },

            {
                type: 'number',
                field: 'Score',
                label: Strings.backgroundCheckConfigurations.SCORE,
                value: obj.Score
            }
        ];

        const textStyle = {
            float: 'right'
        };

        const nameMatchTextStyle = {
            display: (configurations.BackGroundCheckConfigs.ReturnBoostRawData ||
                configurations.BackGroundCheckConfigs.ReturnBoostRawDataIfApproved ||
                configurations.BackGroundCheckConfigs.ReturnBoostRawDataIfNotApproved ? "block" : "none"),

        };

        switch (this.state.selected.text) {
            case Strings.backgroundCheckConfigurations.MENU_BACKGROUND_CKECK_CONFIG[language]:
                return (
                    <div>
                        {/* <SectionTitle text={Strings.backgroundCheckConfigurations.BACKGROUND_CHECK[language]} /> */}

                        <p>{Strings.backgroundCheckConfigurations.BACKGROUND_CHECK_TEXT[language]}</p>

                        <TextField
                            inline
                            type='number'
                            floatingLabelText={Strings.backgroundCheckConfigurations.REPROVATION_POINT[language]}
                            value={configurations.BackGroundCheckConfigs.LimitScore}
                            name='LimitScore'
                            onChange={this.handleReprovationPointChange}
                            errorText={errors ? errors.ScoreRules : ''}
                            tooltip={Strings.backgroundCheckConfigurations.REPROVATION_POINT_TEXT[language]}
                        />
                        
                        <Toggle
                            tooltip={Strings.backgroundCheckConfigurations.RECEIVE_BACKGROUND_CHECK_DATA_TEXT[language]}
                            style={toggleStyle}
                            toggled={configurations.BackGroundCheckConfigs.ReturnBoostRawData}
                            name='ReturnBoostRawData'
                            onToggle={this.handleToggle}
                            label={Strings.backgroundCheckConfigurations.RECEIVE_BACKGROUND_CHECK_DATA[language]}
                        />

                        <TextField
                            tooltip={null}
                            textStyle={ textStyle }
                            labelStyle={{ fontWeight: 300 }}
                            type='text'
                            wrapperStyle={nameMatchTextStyle}
                            floatingLabelText={Strings.backgroundCheckConfigurations.API_TOKEN_BIGBOOST[language]}
                            value={configurations.BigBoostConfigs.Token}
                            name='BigBoostConfigsToken'
                            onChange={this.handleValueMatchChange.bind(null, 'BigBoostConfigsToken')}
                        />
                    </div>
                );
            case Strings.backgroundCheckConfigurations.MENU_BASIC_DATA[language]:
                let basicDataReturnElem = []
                if("KYC_OBIT_STATUS" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    basicDataReturnElem.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name: 'KYC_OBIT_STATUS',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }

                if ("KYC_CPF_STATUS" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    basicDataReturnElem.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name: 'KYC_CPF_STATUS',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }

                if("KYC_AGE" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    configurations.BackGroundCheckConfigs.DataSetsConfigs.KYC_AGE.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_AGE :: obj: `, obj);
                        basicDataReturnElem.push(
                            <CheckboxOperandValue
                                key={"father_KYC_AGE_" + index}
                                handledataToggle={this.handledataToggle}
                                handleValueMatchChange={this.handleValueMatchChange}
                                data={{
                                    name: 'KYC_AGE',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: multiFilter(obj),
                                    displayToggle: index == 0 ? true : false
                                }}
                            />
                        )
                    })
                }

                if("IP_LOCATION" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    basicDataReturnElem.push(
                        <CheckboxOperandValue
                        handledataToggle={this.handledataToggle}
                        handleValueMatchChange={this.handleValueMatchChange}
                        data={{
                            name: 'IP_LOCATION',
                            config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                            stringBase: Strings.backgroundCheckConfigurations,
                            fields: [
                                {
                                    type: 'number',
                                    field: 'Score',
                                    label: Strings.backgroundCheckConfigurations.SCORE
                                }
                            ]
                        }}
                    />
                    )
                }

                return (
                    <div>
                        {/* <SectionTitle text={Strings.backgroundCheckConfigurations.MENU_BASIC_DATA[language]} /> */}

                        {basicDataReturnElem}
                    </div>
                );
            case Strings.backgroundCheckConfigurations.MENU_KNOW_YOUR_CLIENT:
                let KYCReturnElem = []
                if("KYC_PEP" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    KYCReturnElem.push(
                        <CheckboxOperandValue
                        handledataToggle={this.handledataToggle}
                        handleValueMatchChange={this.handleValueMatchChange}
                        data={{
                            name: 'KYC_PEP',
                            config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                            stringBase: Strings.backgroundCheckConfigurations,
                            fields: [
                                {
                                    type: 'number',
                                    field: 'Score',
                                    label: Strings.backgroundCheckConfigurations.SCORE
                                }
                            ]
                        }}
                    />
                    )
                }
                if("KYC_PAST_PEP" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    KYCReturnElem.push(
                        <CheckboxOperandValue
                        handledataToggle={this.handledataToggle}
                        handleValueMatchChange={this.handleValueMatchChange}
                        data={{
                            name: 'KYC_PAST_PEP',
                            config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                            stringBase: Strings.backgroundCheckConfigurations,
                            fields: [
                                {
                                    type: 'number',
                                    field: 'Score',
                                    label: Strings.backgroundCheckConfigurations.SCORE
                                }
                            ]
                        }}
                    />
                    )
                }
                if("KYC_COURT_ORDERS" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    configurations.BackGroundCheckConfigs.DataSetsConfigs.KYC_COURT_ORDERS.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_COURT_ORDERS :: obj: `, obj);
                        KYCReturnElem.push(
                            <CheckboxOperandValue
                                key={"father_KYC_COURT_ORDERS_" + index}
                                handledataToggle={this.handledataToggle}
                                handleValueMatchChange={this.handleValueMatchChange}
                                data={{
                                    name: 'KYC_COURT_ORDERS',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: multiFilter(obj),
                                    displayToggle: index == 0 ? true : false
                                }}
                            />
                        )
                    })
                }
                if("KYC_PF_MONEY_LAUNDERING" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    configurations.BackGroundCheckConfigs.DataSetsConfigs.KYC_PF_MONEY_LAUNDERING.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_PF_MONEY_LAUNDERING :: obj: `, obj);
                        KYCReturnElem.push(
                            <CheckboxOperandValue
                                key={"father_KYC_PF_MONEY_LAUNDERING_" + index}
                                handledataToggle={this.handledataToggle}
                                handleValueMatchChange={this.handleValueMatchChange}
                                data={{
                                    name: 'KYC_PF_MONEY_LAUNDERING',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: multiFilter(obj),
                                    displayToggle: index == 0 ? true : false
                                }}
                            />
                        )
                    })
                }
                if("KYC_TERRORIST" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    configurations.BackGroundCheckConfigs.DataSetsConfigs.KYC_TERRORIST.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_TERRORIST :: obj: `, obj);
                        KYCReturnElem.push(
                            <CheckboxOperandValue
                                key={"father_KYC_TERRORIST_" + index}
                                handledataToggle={this.handledataToggle}
                                handleValueMatchChange={this.handleValueMatchChange}
                                data={{
                                    name: 'KYC_TERRORIST',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: multiFilter(obj),
                                    displayToggle: index == 0 ? true : false
                                }}
                            />
                        )
                    })
                }
                if("KYC_PF_SLAVERY" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    configurations.BackGroundCheckConfigs.DataSetsConfigs.KYC_PF_SLAVERY.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_PF_SLAVERY :: obj: `, obj);
                        KYCReturnElem.push(
                            <CheckboxOperandValue
                                key={"father_KYC_PF_SLAVERY_" + index}
                                handledataToggle={this.handledataToggle}
                                handleValueMatchChange={this.handleValueMatchChange}
                                data={{
                                    name: 'KYC_PF_SLAVERY',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: multiFilter(obj),
                                    displayToggle: index == 0 ? true : false
                                }}
                            />
                        )
                    })
                }
                if("KYC_CNEP_PF" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    KYCReturnElem.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name: 'KYC_CNEP_PF',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                    />
                    )
                }
                if("KYC_CNEP_PF_INACTIVE" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    KYCReturnElem.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name:'KYC_CNEP_PF_INACTIVE',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                    />
                    )
                }
                if("KYC_CVM" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    KYCReturnElem.push(
                        <CheckboxOperandValue
                        handledataToggle={this.handledataToggle}
                        handleValueMatchChange={this.handleValueMatchChange}
                        data={{
                            name: 'KYC_CVM',
                            config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                            stringBase: Strings.backgroundCheckConfigurations,
                            fields: [
                                {
                                    type: 'number',
                                    field: 'Score',
                                    label: Strings.backgroundCheckConfigurations.SCORE
                                }
                            ]
                        }}
                    />
                    )
                }
                if("KYC_CEAF" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    configurations.BackGroundCheckConfigs.DataSetsConfigs.KYC_CEAF.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_CEAF :: obj: `, obj);
                        KYCReturnElem.push(
                            <CheckboxOperandValue
                                key={"father_KYC_CEAF_" + index}
                                handledataToggle={this.handledataToggle}
                                handleValueMatchChange={this.handleValueMatchChange}
                                data={{
                                    name: 'KYC_CEAF',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: multiFilter(obj),
                                    displayToggle: index == 0 ? true : false
                                }}
                            />
                        )
                    })
                }

                return (
                    <div>
                        {/* <SectionTitle text={Strings.backgroundCheckConfigurations.MENU_KNOW_YOUR_CLIENT} /> */}
                        
                        <p>{Strings.backgroundCheckConfigurations.KNOW_YOUR_CLIENT_TEXT[language]}</p>

                        {KYCReturnElem}

                    </div>
                );
            case Strings.backgroundCheckConfigurations.MENU_PUBLIC_AGENT[language]:
                let publicAgentReturnElem = []
                if("PUB_EMP_TYPE" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    publicAgentReturnElem.push(
                        <CheckboxOperandValue
                        handledataToggle={this.handledataToggle}
                        handleValueMatchChange={this.handleValueMatchChange}
                        data={{
                            name: 'PUB_EMP_TYPE',
                            config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                            stringBase: Strings.backgroundCheckConfigurations,
                            fields: [
                                {
                                    type: 'select',
                                    field: 'Value',
                                    default: 'FEDERAL',
                                    values: ['FEDERAL', 'ESTADUAL', 'MUNICIPAL']
                                },
                                {
                                    type: 'number',
                                    field: 'Score',
                                    label: Strings.backgroundCheckConfigurations.SCORE
                                }
                            ]
                        }}
                    />
                    )
                }
                if("PUB_EMP_STATUS" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    publicAgentReturnElem.push(
                        <CheckboxOperandValue
                        handledataToggle={this.handledataToggle}
                        handleValueMatchChange={this.handleValueMatchChange}
                        data={{
                            name: 'PUB_EMP_STATUS',
                            config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                            stringBase: Strings.backgroundCheckConfigurations,
                            fields: [
                                {
                                    type: 'select',
                                    field: 'Value',
                                    default: 'Ativo',
                                    values: ['Ativo', 'Inativo']
                                },
                                {
                                    type: 'number',
                                    field: 'Score',
                                    label: Strings.backgroundCheckConfigurations.SCORE
                                }
                            ]
                        }}
                    />
                    )
                }
                return (
                    <div>
                        {/* <SectionTitle text={Strings.backgroundCheckConfigurations.MENU_PUBLIC_AGENT[language]} /> */}
                        {publicAgentReturnElem}
                        
                    </div>
                );
            case Strings.backgroundCheckConfigurations.MENU_LAWSUITS_PRESENCE[language]:
                return (
                    <div >
                        {/* <SectionTitle text={Strings.backgroundCheckConfigurations.MENU_LAWSUITS_PRESENCE[language]} /> */}

                        { (() => {
                            const dataName = 'PROCESS_COURT_TYPES';
                            const checkBox = (
                                <CheckboxOperandValue
                                    handledataToggle={this.handledataToggle}
                                    handleValueMatchChange={this.handleValueMatchChange}
                                    handleValueMatchMake={this.handleValueMatchMake}
                                    handleScoreToggle={this.handleScoreToggle}
                                    onGenerate={this.onGenerate}
                                    data={{
                                        name: dataName,
                                        config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                        stringBase: Strings.backgroundCheckConfigurations,
                                        word: "",
                                        fields: [
                                            {
                                                type: 'scrollbox',
                                                mainField: 'Types',
                                                field: 'Words'
                                            },
                                            {
                                                type: 'toggle',
                                                field: 'Types'
                                            },
                                            {
                                                type: 'total_number',
                                                mainField: 'Types',
                                                field: 'TotalScore',
                                                label: Strings.backgroundCheckConfigurations.TOTALSCORE[language]
                                            },
                                            {
                                                type: 'selectParty',
                                                mainField: 'Types',
                                                field: 'Party',
                                                values: ["DEFESA", "AUTOR", "NEUTRO"],
                                                translate: {
                                                    "DEFESA": "DEFENDANT",
                                                    "AUTOR": "AUTHOR",
                                                    "NEUTRO" : "NEUTRAL"
                                                },
                                                translateVal: {
                                                    "DEFENDANT": "DEFESA",
                                                    "AUTHOR": "AUTOR",
                                                    "NEUTRAL" : "NEUTRO"
                                                }
                                            },
                                        ],
                                        fieldsArray: {
                                            name: 'Types',
                                            fields: [
                                                {
                                                    type: 'number',
                                                    basictype: 'dict',
                                                    field: 'Scores',
                                                    values: ["Palavras", "Posição", "Tipo"],
                                                    translate: {
                                                        "Palavras": "Words",
                                                        "Posição": "Party",
                                                        "Tipo": "Type"
                                                    }
                                                },
                                                {
                                                    type: 'select',
                                                    basictype: 'list',
                                                    field: 'Value',
                                                    default: '',
                                                    values: courtTypes
                                                },
                                            ]
                                        },


                                    }}
                                />);
                            if (configurations.BackGroundCheckConfigs.DataSetsConfigs[dataName]) {
                                return checkBox;
                            }
                        })()
                        }
                        {(
                            () => {
                                const dataName = 'PROCESS_QTY';
                                const checkBox = (
                                    <CheckboxOperandValue
                                        handledataToggle={this.handledataToggle}
                                        handleValueMatchChange={this.handleValueMatchChange}
                                        data={{
                                            name: dataName,
                                            config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                            stringBase: Strings.backgroundCheckConfigurations,
                                            fields: [
                                                {
                                                    type: 'select',
                                                    field: 'Operand',
                                                    styleWidth: '80px',
                                                    values: ['>=', '=', '<='],
                                                    default: '>='
                                                },
                                                {
                                                    type: 'number',
                                                    field: 'Value'
                                                },
                                                {
                                                    type: 'number',
                                                    field: 'Score',
                                                    label: Strings.backgroundCheckConfigurations.SCORE
                                                }
                                            ]
                                        }}
                                    />
                                );
                                if (configurations.BackGroundCheckConfigs.DataSetsConfigs[dataName]) {
                                    return checkBox;
                                }
                            })()
                        }


                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name: 'PROCESS_UF',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                // incomplete: 'Unidade federativa',
                                fields: [
                                    {
                                        type: 'select',
                                        field: 'Value',
                                        values: ['AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],
                                        default: 'AL'
                                    },
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />

                    </div>
                )
            case Strings.backgroundCheckConfigurations.MENU_INCOME[language]:
                let incomeReturnElem = []
                if("PF_INCOME_ESTIMATE" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    incomeReturnElem.push(
                        <CheckboxOperandValue
                        handledataToggle={this.handledataToggle}
                        handleValueMatchChange={this.handleValueMatchChange}
                        data={{
                            name: 'PF_INCOME_ESTIMATE',
                            config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                            stringBase: Strings.backgroundCheckConfigurations,
                            fields: [
                                {
                                    type: 'select',
                                    field: 'Operand',
                                    styleWidth: '80px',
                                    values: ['>', '=', '<'],
                                    default: '>'
                                },
                                {
                                    type: 'select',
                                    field: 'Value',
                                    values: ['0 A 1 SM', '1 A 2 SM', '2 A 3 SM', '3 A 5 SM', '5 A 7 SM', '7 A 10 SM', '10 A 15 SM', '15 A 20 SM', 'ACIMA DE 20 SM'],
                                    default: '0 A 1 SM'
                                },
                                {
                                    type: 'number',
                                    field: 'Score',
                                    label: Strings.backgroundCheckConfigurations.SCORE
                                }
                            ]
                        }}
                    />
                    )
                }
                return (
                    <div>
                        {incomeReturnElem}
                    </div>
                );
            case Strings.backgroundCheckConfigurations.MENU_DEBIT_PRESENCE[language]:
                let resultElements = []
                if('PF_COLLECTIONS_QTY' in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    resultElements.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name: 'PF_COLLECTIONS_QTY',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'select',
                                        field: 'Operand',
                                        styleWidth: '80px',
                                        values: ['=', '>=', '<='],
                                        default: '='
                                    },
                                    {
                                        type: 'number',
                                        field: 'Value'
                                    },
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }

                if("PF_COLLECTIONS_ORIGINS" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    resultElements.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name: 'PF_COLLECTIONS_ORIGINS',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'select',
                                        field: 'Operand',
                                        styleWidth: '80px',
                                        values: ['=', '>=', '<='],
                                        default: '='
                                    },
                                    {
                                        type: 'number',
                                        field: 'Value'
                                    },
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }

                if("PF_ACTIVE_COLLECTIONS" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    resultElements.push(                        
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name: 'PF_ACTIVE_COLLECTIONS',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }

                return (
                    <div>
                        {/* <SectionTitle text={Strings.backgroundCheckConfigurations.MENU_DEBIT_PRESENCE[language]} /> */}
                        {resultElements}
                    </div>
                );
            case Strings.backgroundCheckConfigurations.MENU_CLASSIST[language]:
                let classistReturnElem = []
                if('CLASS_ORG_NAME' in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    classistReturnElem.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name: 'CLASS_ORG_NAME',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'select',
                                        label: Strings.backgroundCheckConfigurations.SELECT[language],
                                        field: 'Value',
                                        default: 'CREA',
                                        values: ['CREA', 'CONR', 'CRF', 'CREF', 'CRFA', 'AMB', 'CRM', 'CRMV', 'CORE', 'OMB', 'CRN', 'CRO', 'CRP', 'CRQ', 'CONF', 'CORE', 'CRTR']
                                    },
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }
                if('CLASS_ORG_STATUS' in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    classistReturnElem.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name: 'CLASS_ORG_STATUS',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'select',
                                        field: 'Value',
                                        values: ['ATIVO', 'INATIVO'],
                                        default: 'ATIVO'
                                    },
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }
                return (
                    <div>
                        {/* <SectionTitle text={Strings.backgroundCheckConfigurations.MENU_CLASSIST[language]} /> */}

                        {classistReturnElem}
                    </div>
                );
            case Strings.backgroundCheckConfigurations.MENU_SOCIAL_BENEFIT[language]:
                let socialBenefitReturnElem = []
                if('SOCIAL_ASSISTANCE' in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    socialBenefitReturnElem.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name: 'SOCIAL_ASSISTANCE',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }
                return (
                    <div>
                        {/* <SectionTitle text={Strings.backgroundCheckConfigurations.MENU_SOCIAL_BENEFIT[language]} /> */}

                        {socialBenefitReturnElem}
                    </div>
                );
            case Strings.backgroundCheckConfigurations.MENU_RELATIONSHIP[language]:
                let RelationshipReturnElem = []
                let RelationshipReturnElem2 = []
                const dataSetPersonalConfig = {
                    MENU_DEBIT_PRESENCE: {
                        Enabled: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PF_COLLECTIONS_QTY.Enabled
                            || configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PF_COLLECTIONS_ORIGINS.Enabled
                    },
                    MENU_LAWSUITS_PRESENCE: {
                        Enabled: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PROCESS_QTY.Enabled
                            || configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PROCESS_COURT_TYPES.Enabled
                    },
                };
                if('RELATED_PEOPLE' in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    RelationshipReturnElem.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleMatchChange}
                            handleValueMatchMake={this.handleValueRelatedPeopleMatchMake}
                            data={{
                                name: 'RELATED_PEOPLE',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fieldItemArray: {
                                    name: "Relationships",
                                    fields: [
                                        {
                                            type: 'select',
                                            //styleWidth: '80%',
                                            values: ["MOTHER", "SPOUSE", "FATHER", "BROTHER"],
                                            default: 'MOTHER'
                                        }
                                    ]
                                }
                            }}
                        />
                    )
                }
                if("KYC_PEP" in configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs){
                    RelationshipReturnElem2.push(
                        <CheckboxOperandValue
                        handledataToggle={this.handledataRelatedPeopleContentToggle}
                        handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                        data={{
                            name: 'KYC_PEP',
                            config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                            stringBase: Strings.backgroundCheckConfigurations,
                            fields: [
                                {
                                    type: 'number',
                                    field: 'Score',
                                    label: Strings.backgroundCheckConfigurations.SCORE
                                }
                            ]
                        }}
                    />
                    )
                }
                if("KYC_PAST_PEP" in configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs){
                    RelationshipReturnElem2.push(
                        <CheckboxOperandValue
                        handledataToggle={this.handledataRelatedPeopleContentToggle}
                        handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                        data={{
                            name: 'KYC_PAST_PEP',
                            config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                            stringBase: Strings.backgroundCheckConfigurations,
                            fields: [
                                {
                                    type: 'number',
                                    field: 'Score',
                                    label: Strings.backgroundCheckConfigurations.SCORE
                                }
                            ]
                        }}
                    />
                    )
                }
                if("KYC_COURT_ORDERS" in configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs){
                    configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.KYC_COURT_ORDERS.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_COURT_ORDERS :: obj: `, obj);
                        RelationshipReturnElem2.push(
                            <CheckboxOperandValue
                                key={"father_KYC_COURT_ORDERS_" + index}
                                handledataToggle={this.handledataRelatedPeopleContentToggle}
                                handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                data={{
                                    name: 'KYC_COURT_ORDERS',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: multiFilter(obj),
                                    displayToggle: index == 0 ? true : false
                                }}
                            />
                        )
                    })
                }
                if("KYC_PF_MONEY_LAUNDERING" in configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs){
                    configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.KYC_PF_MONEY_LAUNDERING.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_PF_MONEY_LAUNDERING :: obj: `, obj);
                        RelationshipReturnElem2.push(
                            <CheckboxOperandValue
                                key={"father_KYC_PF_MONEY_LAUNDERING_" + index}
                                handledataToggle={this.handledataRelatedPeopleContentToggle}
                                handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                data={{
                                    name: 'KYC_PF_MONEY_LAUNDERING',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: multiFilter(obj),
                                    displayToggle: index == 0 ? true : false
                                }}
                            />
                        )
                    })
                }
                if("KYC_TERRORIST" in configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs){
                    configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.KYC_TERRORIST.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_TERRORIST :: obj: `, obj);
                        RelationshipReturnElem2.push(
                            <CheckboxOperandValue
                                key={"father_KYC_TERRORIST_" + index}
                                handledataToggle={this.handledataRelatedPeopleContentToggle}
                                handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                data={{
                                    name: 'KYC_TERRORIST',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: multiFilter(obj),
                                    displayToggle: index == 0 ? true : false
                                }}
                            />
                        )
                    })
                }
                if("KYC_PF_SLAVERY" in configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs){
                    configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.KYC_PF_SLAVERY.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_PF_SLAVERY :: obj: `, obj);
                        RelationshipReturnElem2.push(
                            <CheckboxOperandValue
                                key={"father_KYC_PF_SLAVERY_" + index}
                                handledataToggle={this.handledataRelatedPeopleContentToggle}
                                handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                data={{
                                    name: 'KYC_PF_SLAVERY',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: multiFilter(obj),
                                    displayToggle: index == 0 ? true : false
                                }}
                            />
                        )
                    })
                }
                if("KYC_CNEP_PF" in configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs){
                    RelationshipReturnElem2.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name: 'KYC_CNEP_PF',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                    />
                    )
                }
                if("KYC_CNEP_PF_INACTIVE" in configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs){
                    RelationshipReturnElem2.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'KYC_CNEP_PF_INACTIVE',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                    />
                    )
                }
                if("KYC_CVM" in configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs){
                    RelationshipReturnElem2.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name: 'KYC_CVM',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                    />
                    )
                }
                if("KYC_CEAF" in configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs){
                    configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.KYC_CEAF.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_CEAF :: obj: `, obj);
                        RelationshipReturnElem2.push(
                            <CheckboxOperandValue
                                key={"father_KYC_CEAF_" + index}
                                handledataToggle={this.handledataRelatedPeopleContentToggle}
                                handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                data={{
                                    name: 'KYC_CEAF',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: multiFilter(obj),
                                    displayToggle: index == 0 ? true : false
                                }}
                            />
                        )
                    })
                }
                
                return (
                    <div>
                        {/* <SectionTitle text={Strings.backgroundCheckConfigurations.MENU_RELATIONSHIP[language]} /> */}

                        {RelationshipReturnElem}
                        <div>
                            {RelationshipReturnElem2}
                            <div>
                                {/** This feature will be reinstante in future when BGC implement it on server side */}
                                {/* <CheckboxOperandValue
                                    handledataToggle={this.handledataRelatedPeopleContentToggle}
                                    handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                    data={{
                                        name: 'PUB_EMP_ORG_NAME',
                                        config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                        stringBase: Strings.backgroundCheckConfigurations,
                                        fields: [
                                            {
                                                type: 'text',
                                                field: 'Value',
                                                default: 'TODAS',
                                                label: Strings.backgroundCheckConfigurations.ORGANIZATION
                                            },
                                            {
                                                type: 'number',
                                                field: 'Score',
                                                label: Strings.backgroundCheckConfigurations.SCORE
                                            }
                                        ]
                                    }}
                                /> */}

                                {
                                    (!configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PUB_EMP_ORG_NAME.Enabled) ? null :
                                        (
                                            <CheckboxOperandValue
                                                handledataToggle={this.handledataRelatedPeopleContentToggle}
                                                handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                                data={{
                                                    name: 'PUB_EMP_TYPE',
                                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                                    stringBase: Strings.backgroundCheckConfigurations,
                                                    fields: [
                                                        {
                                                            type: 'select',
                                                            field: 'Value',
                                                            default: 'FEDERAL',
                                                            values: ['FEDERAL', 'ESTADUAL', 'MUNICIPAL']
                                                        },
                                                        {
                                                            type: 'number',
                                                            field: 'Score',
                                                            label: Strings.backgroundCheckConfigurations.SCORE
                                                        }
                                                    ]
                                                }}
                                            />
                                        )
                                }

                                {
                                    (!configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PUB_EMP_ORG_NAME.Enabled) ? null :
                                        (

                                            <CheckboxOperandValue
                                                handledataToggle={this.handledataRelatedPeopleContentToggle}
                                                handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                                data={{
                                                    name: 'PUB_EMP_STATUS',
                                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                                    stringBase: Strings.backgroundCheckConfigurations,
                                                    fields: [
                                                        {
                                                            type: 'select',
                                                            field: 'Value',
                                                            default: 'Ativo',
                                                            values: ['Ativo', 'Inativo']
                                                        },
                                                        {
                                                            type: 'number',
                                                            field: 'Score',
                                                            label: Strings.backgroundCheckConfigurations.SCORE
                                                        }
                                                    ]
                                                }}
                                            />
                                        )
                                }
                            </div>

                            <CheckboxOperandValue
                                handledataToggle={this.toggleDebit}
                                data={{
                                    name: 'MENU_DEBIT_PRESENCE',
                                    config: dataSetPersonalConfig,
                                    stringBase: Strings.backgroundCheckConfigurations
                                }}
                            />

                            {(!dataSetPersonalConfig.MENU_DEBIT_PRESENCE.Enabled) ? null :
                                (
                                    <div>
                                        <CheckboxOperandValue
                                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                            data={{
                                                name: 'PF_COLLECTIONS_QTY',
                                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                                stringBase: Strings.backgroundCheckConfigurations,
                                                fields: [
                                                    {
                                                        type: 'select',
                                                        field: 'Operand',
                                                        styleWidth: '80px',
                                                        values: ['=', '>=', '<='],
                                                        default: '='
                                                    },
                                                    {
                                                        type: 'number',
                                                        field: 'Value'
                                                    },
                                                    {
                                                        type: 'number',
                                                        field: 'Score',
                                                        label: Strings.backgroundCheckConfigurations.SCORE
                                                    }
                                                ]
                                            }}
                                        />

                                        <CheckboxOperandValue
                                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                            data={{
                                                name: 'PF_COLLECTIONS_ORIGINS',
                                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                                stringBase: Strings.backgroundCheckConfigurations,
                                                fields: [
                                                    {
                                                        type: 'select',
                                                        field: 'Operand',
                                                        styleWidth: '80px',
                                                        values: ['=', '>=', '<='],
                                                        default: '='
                                                    },
                                                    {
                                                        type: 'number',
                                                        field: 'Value'
                                                    },
                                                    {
                                                        type: 'number',
                                                        field: 'Score',
                                                        label: Strings.backgroundCheckConfigurations.SCORE
                                                    }
                                                ]
                                            }}
                                        />
                                    </div>
                                )
                            }

                            <CheckboxOperandValue
                                handledataToggle={this.handledataRelatedPeopleContentToggle}
                                handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                data={{
                                    name: 'PROCESS_QTY',
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: [
                                        {
                                            type: 'select',
                                            field: 'Operand',
                                            styleWidth: '80px',
                                            values: ['>=', '=', '<='],
                                            default: '>='
                                        },
                                        {
                                            type: 'number',
                                            field: 'Value'
                                        },
                                        {
                                            type: 'number',
                                            field: 'Score',
                                            label: Strings.backgroundCheckConfigurations.SCORE
                                        }
                                    ]
                                }}
                            />

                            {(() => {
                                const dataName = 'PROCESS_COURT_TYPES';
                                const checkBox = (
                                    <CheckboxOperandValue
                                        id="related_court_types"
                                        handledataToggle={this.handledataRelatedPeopleContentToggle}
                                        handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                        handleValueMatchMake={this.handleValueRelatedPeopleContentMatchMake}
                                        handleScoreToggle={this.handleScoreRelatedToggle}
                                        onGenerate={this.onGenerateRelatedPeople}
                                        data={{
                                            name: dataName,
                                            config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                            stringBase: Strings.backgroundCheckConfigurations,
                                            word: "",
                                            fields: [
                                                {
                                                    type: 'scrollbox',
                                                    mainField: 'Types',
                                                    field: 'Words'
                                                },
                                                {
                                                    type: 'toggle',
                                                    field: 'Types'
                                                },
                                                {
                                                    type: 'total_number',
                                                    mainField: 'Types',
                                                    field: 'TotalScore',
                                                    label: Strings.backgroundCheckConfigurations.TOTALSCORE[language]
                                                },
                                                {
                                                    type: 'selectParty',
                                                    mainField: 'Types',
                                                    field: 'Party',
                                                    values: ["DEFESA", "AUTOR", "NEUTRO"],
                                                    translate: {
                                                        "DEFESA": "DEFENDANT",
                                                        "AUTOR": "AUTHOR",
                                                        "NEUTRO": "NEUTRAL"
                                                    },
                                                    translateVal: {
                                                        "DEFENDANT": "DEFESA",
                                                        "AUTHOR": "AUTOR",
                                                        "NEUTRAL": "NEUTRO"
                                                    }
                                                },
                                            ],
                                            fieldsArray: {
                                                name: 'Types',
                                                fields: [
                                                    {
                                                        type: 'number',
                                                        basictype: 'dict',
                                                        field: 'Scores',
                                                        values: ["Palavras", "Posição", "Tipo"],
                                                        translate: {
                                                            "Palavras": "Words",
                                                            "Posição": "Party",
                                                            "Tipo": "Type"
                                                        }
                                                    },
                                                    {
                                                        type: 'select',
                                                        basictype: 'list',
                                                        field: 'Value',
                                                        default: '',
                                                        values: courtTypes
                                                    },
                                                ]
                                            },
                                        }}
                                    />);
                                if (configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[dataName]) {
                                    return checkBox;
                                }
                            })()
                            }

                            <CheckboxOperandValue
                                handledataToggle={this.handledataRelatedPeopleContentToggle}
                                handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                data={{
                                    name: 'PROCESS_UF',
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: [
                                        {
                                            type: 'select',
                                            field: 'Value',
                                            values: ['AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],
                                            default: 'AL'
                                        },
                                        {
                                            type: 'number',
                                            field: 'Score',
                                            label: Strings.backgroundCheckConfigurations.SCORE
                                        }
                                    ]
                                }}
                            />

                            {/* <CheckboxOperandValue
                                handledataToggle={this.handledataRelatedPeopleContentToggle}
                                handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                data={{
                                    name: 'CELEBRITY_LEVEL',
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: [
                                        {
                                            type: 'number',
                                            field: 'Score',
                                            label: Strings.backgroundCheckConfigurations.SCORE
                                        }
                                    ]
                                }}
                            /> */}

                            <CheckboxOperandValue
                                handledataToggle={this.handledataRelatedPeopleContentToggle}
                                handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                data={{
                                    name: 'SOCIAL_ASSISTANCE',
                                    config: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations,
                                    fields: [
                                        {
                                            type: 'number',
                                            field: 'Score',
                                            label: Strings.backgroundCheckConfigurations.SCORE
                                        }
                                    ]
                                }}
                            />
                        </div>
                    </div>
                );
            case Strings.backgroundCheckConfigurations.MENU_RELATIONSHIP_PJ[language]:
                let returnElem = []
                if("KYC_CNEP_REL_CNPJ" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    returnElem.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name: 'KYC_CNEP_REL_CNPJ',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />
                    )                    
                }

                if("KYC_CNEP_REL_CNPJ_INACTIVE" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    returnElem.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name: 'KYC_CNEP_REL_CNPJ_INACTIVE',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />
                    )                    
                }

                if("PF_REL_CNPJ_QTY" in configurations.BackGroundCheckConfigs.DataSetsConfigs){
                    returnElem.push(
                        <CheckboxOperandValue
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name: 'PF_REL_CNPJ_QTY',
                                config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />
                    )                    
                }

                return (

                    <div>
                        {/* <SectionTitle text={Strings.backgroundCheckConfigurations.MENU_RELATIONSHIP_PJ[language]} /> */}
                        {returnElem}
                    </div>
                );

                
            // case Strings.backgroundCheckConfigurations.MENU_CELEBRITY[language]:
            //     return (
            //         <div>
            //             {/* <SectionTitle text={Strings.backgroundCheckConfigurations.MENU_CELEBRITY[language]} /> */}

            //             <CheckboxOperandValue
            //                 handledataToggle={this.handledataToggle}
            //                 handleValueMatchChange={this.handleValueMatchChange}
            //                 data={{
            //                     name: 'CELEBRITY_LEVEL',
            //                     config: configurations.BackGroundCheckConfigs.DataSetsConfigs,
            //                     stringBase: Strings.backgroundCheckConfigurations,
            //                     fields: [
            //                         {
            //                             type: 'number',
            //                             field: 'Score',
            //                             label: Strings.backgroundCheckConfigurations.SCORE
            //                         }
            //                     ]
            //                 }}
            //             />
            //         </div>
            //     )

            default:
                return null;
        }
    }

    render() {
        const configurations = this.state.configurations;

        // If the client config is missing, for whatever reason, any info, this code block will try and fullfill it
        if(this.props.refConfigurations){
            const backGroundCheckConfigs_default = this.props.refConfigurations.BackGroundCheckConfigs;
            configurations.BackGroundCheckConfigs = configurations.BackGroundCheckConfigs || {};
            configurations.BackGroundCheckConfigs = { ...backGroundCheckConfigs_default, ...configurations.BackGroundCheckConfigs };
            configurations.BackGroundCheckConfigs.DataSetsConfigs = {
                ...backGroundCheckConfigs_default.DataSetsConfigs,
                ...configurations.BackGroundCheckConfigs.DataSetsConfigs
            };
            configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE ={
                ...backGroundCheckConfigs_default.DataSetsConfigs.RELATED_PEOPLE,
                ...configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE

            }
            configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs ={
                ...backGroundCheckConfigs_default.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs,
                ...configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs
            }
        }

        const errors = validate(this.state.configurations, this.constraints, this.options);

        return (
            <div style={cardContainerStyle} className="bgc-wrapper">
                <div key='left-column' style={columnStyle} className="bgc-top-content">
                    <LoadingDialog open={this.state.loading} />

                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row' }}>

                        {this.state.menuItens.map((item, index) => {
                            return (
                                <List component="nav" style={{padding: 0}}>
                                    <ListItem selected={true}
                                        key={this.state.DEFAULT_KEY + index}
                                        onClick={this.changeSelected.bind(null, item)}
                                        style={Object.assign({}, {cursor: "pointer"}, this.state.selected.text === this.state.menuItens[index].text ? selectedSubItemStyle : subItemStyle)}
                                    >
                                        <ListItemText id="ListItemText" primary={item.text} />
                                    </ListItem>
                                </List>
                            )
                        })}
                    </div>
                </div>

                <div key='right-column' style={column2Style} className="bgc-bottom-content">
                    <LoadingDialog open={this.state.loading} />

                    {this.renderContent(configurations, errors)}
                    {this.handleDontLetSaveGroupWithoutToken(configurations)}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        },

        onGroupDeleted: (groupsNames) => {
            dispatch(actions.storeGroupsNames(groupsNames));
        }

    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        refConfigurations: state ? state.refConfigurations : {},
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundCheck);
